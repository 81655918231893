import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Board from 'react-trello';
import _ from 'underscore';

function PublicDashboard(props) {

    const [data, setData] = useState({ lanes: [] });

    useEffect(() => {
        axios.get('https://qrmenu.mn/odoo/getTasks')
            .then(res => {
                let items = []
                let lanes = res.data.types;
                let tmpcards = _.groupBy(res.data.tasks, (card) => { return card.stage_id[0] })
                lanes.forEach(element => {
                    let cards = []
                    if (tmpcards[element.id] !== undefined) {
                        tmpcards[element.id].forEach(card => {
                            let description = ''
                            if (card.description) {
                                description = card.description.replace(/(<([^>]+)>)/ig, '').substr(0, 40);
                            }

                            description += '\nҮүсгэсэн огноо: ' + card.create_date
                            description += '\nСүүлийн шинэчлэл: ' + card.date_last_stage_update

                            cards.push({ id: '' + card.id, title: card.name, description: description, draggable: false , editable: false })
                        })
                    }
                    items.push({ id: element.id + '', title: element.name, cards: cards, label: cards.length + ''})
                });

                setData({ lanes: items })
            })
    }, [])

    return (
        <div>
            <Board data={data} />
        </div>
    );

}

export default PublicDashboard;