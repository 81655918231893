import React, { useState, useEffect } from 'react';
import { Call } from '../services/Call';
import _ from 'underscore';


function Report(props) {

    const [data, setData] = useState([]);
    const [origData, setOrigData] = useState([]);
    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);
    const columns = [
        {
            name: 'Захиалгын дугаар',
            // selector: row => row.title,
        },
        {
            name: 'Харилцагчийн нэр',
            selector: row => row.name,
        },
        {
            name: 'Харилцагчийн утас',
            selector: row => row.x_menu_phone,
        },
        {
            name: 'Харилцагчийн код',
            selector: row => row.x_menu_code,
        },
        {
            name: 'Меню төрөл',
            selector: row => {
                if (row.x_menu_data !== undefined) {
                    let item = JSON.parse(row.x_menu_data)
                    if (item['turul'] !== 'null')
                        return item['turul']
                }
                return '';
            },
        },
        {
            name: 'Хэмжээ',
            selector: row => {
                if (row.x_menu_data !== undefined) {
                    let item = JSON.parse(row.x_menu_data)
                    if (item['size'] !== 'null')
                        return item['size']
                }
                return '';
            },
        },
        {
            name: 'Захиалсан огноо',
            selector: row => row.year,
        },
        {
            name: 'Үнийн дүн',
            selector: row => row.year,
        },
        {
            name: 'Үе шат',
            selector: row => row.stage_id[1],
        },
        {
            name: 'Тайлбар',
            selector: row => row.description,
        },
    ];

    useEffect(() => {
        const setContextCards = (err, res) => {
            let tmp = [];
            _.map(res, (item, i) =>{
                let menudata = [] , 
                    turul = '', 
                    size = '', 
                    perc = '', 
                    page = '',
                    stage = '',
                    description = '',
                    create_date = '',
                    date_last_stage_update = '';

                if(item.x_menu_data!== undefined){
                    menudata = JSON.parse(item.x_menu_data)
                    if(menudata !== undefined && menudata.turul !== undefined && menudata.turul !== 'null') {
                        turul = menudata.turul;
                    }
                    if(menudata !== undefined && menudata.size !== undefined && menudata.size !== 'null') {
                        size = menudata.size;
                    }
                    if(menudata !== undefined && menudata.menu_page_count !== undefined && menudata.menu_page_count !== 'null') {
                        page = menudata.menu_page_count;
                    }
                    if(menudata !== undefined && menudata.menu_page_count !== undefined && menudata.menu_print_count !== 'null') {
                        perc = menudata.menu_print_count;
                    }
                }

                create_date = item.create_date
                date_last_stage_update = item.date_last_stage_update

                if(item.stage_id !== undefined){
                    stage = item.stage_id[1];
                }
                if(item.description !== false){
                    description = item.description.replace(/<[^>]*>?/gm, '');;
                }

                tmp.push({
                    'Захиалгын дугаар': '',
                    'Харилцагчийн нэр': item.name,
                    'Харилцагчийн код': item.x_menu_code,
                    'Харилцагчийн утас': item.x_menu_phone,
                    'Хэмжээ': size,
                    'Меню төрөл': turul,
                    'Хуудасны тоо': page,
                    'Хувь': perc,
                    'Захиалсан огноо': '',
                    'Үнийн дүн': '',
                    'Үе шат':  stage,
                    'Үүссэн': create_date,
                    'Сүүлд шинэчилсэн': date_last_stage_update,
                    'Тайлбар': description,
                })
            })
            setData(tmp)
            setOrigData(tmp)
        }

        let params = {
            domain: [['project_id', '=', 6]],
            order: 'sequence ASC',
            fields: ['id',
                'name',
                'description',
                'stage_id',
                'x_menu_phone',
                'x_menu_type',
                'x_menu_data',
                'x_menu_channel',
                'x_menu_code',
                'x_menu_partners',
                'date_last_stage_update',
                'create_date'
            ],
        };

        Call().search_read('project.task', params, setContextCards);

    }, [])

    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);
        result = '\uFEFF';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;

            });
            result += lineDelimiter;
        });
        return result;

    }
    function downloadCSV(array) {

        const link = document.createElement('a');

        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const filterData = (value) => {
        let filtered = origData;
        // console.log(value)
        if(value){
            filtered = _.filter(data , function(d){
                return d['Захиалгын дугаар'].toLowerCase().includes(value) ||
                    d['Харилцагчийн нэр'].toLowerCase().includes(value) ||
                    d['Харилцагчийн код'].toLowerCase().includes(value) ||
                    d['Хэмжээ'].toLowerCase().includes(value) ||
                    d['Меню төрөл'].toLowerCase().includes(value) ||
                    d['Хуудасны тоо'].toLowerCase().includes(value) ||
                    d['Хувь'].toLowerCase().includes(value) ||
                    d['Захиалсан огноо'].toLowerCase().includes(value) ||
                    d['Үнийн дүн'].toLowerCase().includes(value) ||
                    d['Үе шат'].toLowerCase().includes(value) ||
                    d['Тайлбар'].toLowerCase().includes(value);
            });
        }

        setData(filtered);
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center p-3">
                <div>
                    <h5 className="m-0">Тайлан</h5>
                </div>
                <div>
                    <div className="d-flex gap-2">
                        <input type="text" className="form-control" placeholder="Дурын утгаар хайж болно ..." onChange={e => filterData(e.target.value)} />
                        <span className="btn btn-success px-4" onClick={()=>downloadCSV(data)}>Export</span>
                    </div>
                </div>
            </div>
            <div className="table-responsive p-3">
                {/* <DataTable pagination selectableRows className="table table-bordered table-striped" columns={columns} data={data} /> */}
                <table className="table table-bordered table-striped table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th>Захиалгын дугаар</th>
                            <th>Харилцагчийн нэр</th>
                            <th>Харилцагчийн утас</th>
                            <th>Харилцагчийн код</th>
                            <th>Хэмжээ</th>
                            <th>Меню төрөл</th>
                            <th>Хуудасны тоо</th>
                            <th>Хувь</th>
                            <th>Захиалсан огноо</th>
                            <th>Үнийн дүн</th>
                            <th>Үе шат</th>
                            <th>Үүссэн</th>
                            <th>Сүүлд шинэчилсэн</th>
                            <th>Тайлбар</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(data, (item, i) => {
                            return(
                                <tr key={'row-'+i}>
                                    <td>{item['Захиалгын дугаар']}</td>
                                    <td>{item['Харилцагчийн нэр']}</td>
                                    <td>{item['Харилцагчийн утас']}</td>
                                    <td>{item['Харилцагчийн код']}</td>
                                    <td>{item['Хэмжээ']}</td>
                                    <td>{item['Меню төрөл']}</td>
                                    <td>{item['Хуудасны тоо']}</td>
                                    <td>{item['Хувь']}</td>
                                    <td>{item['Захиалсан огноо']}</td>
                                    <td>{item['Үнийн дүн']}</td>
                                    <td>{item['Үе шат']}</td>
                                    <td>{item['Үүссэн']}</td>
                                    <td>{item['Сүүлд шинэчилсэн']}</td>
                                    <td>{item['Тайлбар']}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Report;