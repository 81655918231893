import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Login from './pages/auth/Login'
import Dashboard from './pages/Dashboard'
import Order from './pages/Order'
import Header from './pages/Header'
import Report from './pages/Report'
import Print from './pages/Print'
import PublicDashboard from './pages/PublicDashboard'
import { apiUrl } from './services/config';

function App() {

  const [authenticated, setAuthentication] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState(false);

  // let token = window.localStorage.getItem('uid')

  useEffect(() => {
    const isLogin = () => {
      let data = JSON.stringify({
        "jsonrpc": "2.0",
        "params": {
          // "session_id": localStorage.getItem('session_id')
        }
      });

      let config = {
        method: 'POST',
        url: apiUrl + '/web/session/get_session_info',
        headers: {
          'Content-type': 'application/json',
        },
        withCredentials: true,
        data: data
      };

      axios(config)
        .then(result => {
          if (!result.data.error) {
            setAuthentication(result);
          }
        })

      setLoadingComplete(true);
    }
    isLogin();
  }, []);

  // console.log( authenticated );

  if (loadingComplete) {
    return (
      <div>
        <BrowserRouter>
          {authenticated ? <Header /> : ""}
          <Routes>
            {authenticated ?
              <React.Fragment>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path="/new" element={<Order />}></Route>
                <Route path="/order/:id" element={<Order />}></Route>
                <Route path="/print/:id" element={<Print />}></Route>
                <Route path="/report" element={<Report />}></Route>
              </React.Fragment>
              : <React.Fragment>
                <Route path="/" element={<Login />} />
              </React.Fragment>}
            <Route path="/public/dashboard" element={<PublicDashboard />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
    )
  } else {
    return (
      <div> Loading ... </div>
    )
  }

}

export default App;
