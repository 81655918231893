import Button from '@restart/ui/esm/Button';
import React, { useState } from 'react';
import axios from 'axios';
import { Card, Col, Container, FormControl, FormLabel, Row } from 'react-bootstrap';
import {apiUrl, dbName, port} from '../../services/config'
import { toast } from 'react-toastify';
// import Odoo from '../../services/Odoo'

function Login(props) {

    const [loginInput, setLoginInput] = useState({
        'email': null,
        'password': null,
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        var data = JSON.stringify({
            "jsonrpc": "2.0",
            "params": {
                "login": loginInput['email'],
                "password": loginInput['password'],
                "db": dbName
            }
        });

        var config = {
            method: 'post',
            url: apiUrl + '/web/session/authenticate',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin' : '*'
            },
            withCredentials: true,
            data: data
        };
        
        axios(config)
            .then(function (response) {
                if(response.data.error && response.data.error.data.message){
                    toast.warn(response.data.error.data.message);
                }
                if(response.data.result){
                    window.location.reload(); 
                }
            })
            .catch(function (error) {
                toast.warn('sad');
                console.log(error);
            });

    }

    return (
        <div>
            <Container>
                <Row className="justify-content-center">
                    <Col md="8" sm="8" lg="6">
                        <Card className="mt-5 mb-3">
                            <Card.Body className="bg-light">
                                <h4 className="mb-3">Ухаалаг меню</h4>
                                <form onSubmit={handleSubmit} method="post">
                                    <FormLabel>Имэйл хаяг:</FormLabel>
                                    <FormControl required type="email" placeholder="Имэйл хаягаа оруулна уу..." onChange={(e) => setLoginInput({ ...loginInput, email: e.target.value })} className="mb-3" />
                                    <FormLabel>Нууц үг:</FormLabel>
                                    <FormControl required type="password" placeholder="Нууц үгээ оруулна уу..." onChange={(e) => setLoginInput({ ...loginInput, password: e.target.value })} className="mb-3" />
                                    <Button type="submit" className="btn btn-primary w-100">Нэвтрэх</Button>
                                </form>
                            </Card.Body>
                        </Card>
                        <span className="text-muted">By: Мөнгөн ганзай ХХК</span>
                        <a href='/public/dashboard' className='text-muted text-decoration-none ms-3'>Public</a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Login;