import React, { useState, useEffect } from 'react';
import Board from 'react-trello';
import { Call } from '../services/Call';
import _ from 'underscore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiUrl, port } from '../services/config'
import { toast } from 'react-toastify';

function Dashboard(props) {

    let navigate = useNavigate()

    const [data, setData] = useState({ lanes: [] });

    const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position) => {
        console.log('handleDragEnd', cardId, sourceLaneId, targetLaneId, position);

        let params = {
            id: parseInt(cardId),
            stage_id: parseInt(targetLaneId),
            position: parseInt(position)
        }

        let config = {
            method: 'POST',
            url: apiUrl + '/odoo_controller/task_change',
            withCredentials: true,
            data: params
        };

        axios(config)
            .then(res => {
                toast.success('Амжилттай')
            })
            .catch(err => {
                toast.warning('Алдаа гарлаа систем админд мэдэгдэнэ үү')
            })

        // Call( ).update('project.task', params, [ parseInt(cardId) ], setUpdate);
    }

    const onCardClick = (cardId, metadata, laneId) => {
        // console.log( cardId, metadata, laneId )
        navigate('/order/' + cardId)

    }

    useEffect(() => {
        const setStages = (err, res) => {

            let items = []
            let lanes = res;

            const setContextCards = (err, res) => {
                let tmpcards = _.groupBy(res, (card) => { return card.stage_id[0] })
                lanes.forEach(element => {
                    let cards = []
                    if (tmpcards[element.id] !== undefined) {
                        tmpcards[element.id].forEach(card => {
                            let description = ''
                            if (card.description) {
                                description = card.description.replace(/(<([^>]+)>)/ig, '').substr(0, 40);
                            }

                            description += '\nҮүсгэсэн огноо: ' + card.create_date
                            description += '\nСүүлийн шинэчлэл: ' + card.date_last_stage_update

                            cards.push({ id: '' + card.id, title: card.name, description: description })
                        })
                    }
                    items.push({ id: element.id + '', title: element.name, cards: cards, label: cards.length + ''})
                });
                setData({ lanes: items })
            }

            let params = {
                domain: [['project_id', '=', 6]],
                order: 'sequence ASC',
                fields: ['id', 'name', 'stage_id', 'description', 'date_assign', '__last_update','date_last_stage_update','create_date'],
            };

            Call().search_read('project.task', params, setContextCards);

        }


        let paramsType = {
            domain: [['project_ids', '=', 6]],
            order: 'sequence ASC',
            fields: ['id', 'name'],
        };

        Call().search_read('project.task.type', paramsType, setStages);


    }, [])

    if (data.lanes.length > 0) {
        return (
            <Board data={data} handleDragEnd={handleDragEnd} onCardClick={onCardClick} />
        );
    }
    else {
        return (
            <div>
                Loading ...
            </div>
        )
    }
}

export default Dashboard;