import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Col, Container, FormControl, Form, FormSelect, Row } from 'react-bootstrap';
import _ from 'underscore';
import { useForm } from "react-hook-form";
import { Call } from '../services/Call';
import { Link, useParams } from 'react-router-dom';

import beerList from './json/beer.json';
import brownList from './json/brown.json';
import vodkaList from './json/vodka.json';
import waterList from './json/water.json';
import wineList from './json/wine.json';
import { menu } from './json/menu.js';

import { apiUrl, port } from '../services/config'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';

function Order(props) {

    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

    let params = useParams();

    const onSubmit = data => {

        const sentParams = {
            project_id: 6,
            company_id: 1,
            // kanban_state: 166,
            name: data.company_name,
            x_menu_type: data.company_category,
            x_menu_code: data.company_code,
            x_menu_channel: data.company_channel,
            x_menu_price: JSON.stringify(data.price),
            x_menu_data: JSON.stringify(data.menu),
            x_menu_partners: JSON.stringify(data.kht),
            x_menu_phone: data.company_phone,
            description: data.description || null
        }

        if (data.id !== undefined) {
            sentParams['id'] = data.id;
        }

        let config = {
            method: 'POST',
            url: apiUrl + '/odoo_controller/task',
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            withCredentials: true,
            data: sentParams
        };

        axios(config)
            .then(res => {
                toast.success('Амжилттай')
                setCarData(sentParams['id'])
            })
            .catch(err => {
                toast.warning('Алдаа гарлаа систем админд мэдэгдэнэ үү')
            })


    };

    let [kht, setKht] = useState([]);

    const categories = ['D', 'G', 'S', 'B', 'I'];
    const channels = ['RSA', 'RSB', 'BRA', 'BRB', 'KRA', 'KRB', 'HLA', 'HLB', 'NCA', 'NCB', 'CAF', 'EHL', 'OTR'];
    const khtList = [];
    khtList['1'] = "АПУ Трейдинг";
    khtList['2'] = "СББ Трейд";
    khtList['3'] = "Import /Wine/";
    khtList['4'] = "Draught beer";

    const menus = menu;

    useEffect(() => {
        axios.get(apiUrl + '/odoo_controller/get_users', { withCredentials: true })
            .then(res => {
                let data = _(res.data).groupBy('x_kht_id');
                setKht(data)
            })
            .catch(err => {
                console.log('err', err);
            })
    }, [setKht])

    const setCarData = (cardId) => {

        const setForm = (err, res) => {
            if (res.length > 0) {
                setValue('id', res[0]['id'])
                setValue('company_name', res[0]['name'])
                setValue('description', res[0]['description'])
                setValue('company_phone', res[0]['x_menu_phone'])
                setValue('company_code', res[0]['x_menu_code'])
                setValue('company_channel', res[0]['x_menu_channel'])
                setValue('company_category', res[0]['x_menu_type'])

                let price = JSON.parse(res[0]['x_menu_price']);
                let menu = JSON.parse(res[0]['x_menu_data']);
                let partners = JSON.parse(res[0]['x_menu_partners']);

                setValue('price', price)
                setValue('menu', menu)
                setValue('kht', partners)
            }

        }

        let task = {
            domain: [['id', '=', params.id]],
            fields: [
                'id',
                'name',
                'description',
                'x_menu_phone',
                'x_menu_type',
                'x_menu_price',
                'x_menu_data',
                'x_menu_channel',
                'x_menu_code',
                'x_menu_partners'
            ],
            limit: 1
        }

        Call().search_read('project.task', task, setForm)
    }

    if (params['id'] !== undefined) {
        setCarData(params['id'])
    }

    return (
        <Container className="py-3">
            {params['id'] !== undefined ? (
                <div className="d-flex justify-content-between">
                    <h5 className="fw-lighter text-muted m-0">Засварлах</h5>
                    <Link to={'/print/' + params['id']}>Хэвлэх</Link>
                </div>
            ) : (
                <h5 className="fw-lighter text-muted">Шинэ захиалга үүсгэх</h5>
            )}
            <hr />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card bg-light mb-3">
                    <div className="card-body">
                        <Row>
                            <Col lg="4" className="mb-3">
                                <Form.Label> Харилцагчийн код: * </Form.Label>
                                <FormControl placeholder="Харилцагч байгууллагын код оруулна уу ..." {...register("company_code", { required: false })} />
                                {errors.company_code && <span className="text-danger">Байгууллагын код оруулах шаардлагатай</span>}
                            </Col>
                            <Col lg="4" className="mb-3">
                                <Form.Label> Харилцагчийн нэр: * </Form.Label>
                                <FormControl placeholder="Харилцагч байгууллагын нэрийг оруулна уу ..." {...register("company_name", { required: true })} />
                                {errors.company_name && <span className="text-danger">Байгууллагын нэр оруулах шаардлагатай</span>}
                            </Col>
                            <Col lg="4" className="mb-3">
                                <Form.Label> Харилцагчийн утас: * </Form.Label>
                                <FormControl placeholder="Харилцагчийн холбоо барих утас оруулна уу ..." {...register("company_phone", { required: false })} />
                                {errors.company_phone && <span className="text-danger">Утасны дугаар оруулах шаардлагатай</span>}
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="6" className="mb-3">
                                <Form.Label> Харилцагчийн ангилал: * </Form.Label>
                                <FormSelect {...register("company_category", { required: true })}>
                                    {Object.keys(categories).map(i => (
                                        <option key={'category' + i} value={categories[i]}>{categories[i]}</option>
                                    ))}
                                </FormSelect>
                                {errors.company_category && <span className="text-danger">Байгууллагын ангилал сонгох шаардлагатай</span>}
                            </Col>
                            <Col lg="6" className="mb-3">
                                <Form.Label> Харилцагчийн суваг: * </Form.Label>
                                <FormSelect {...register("company_channel", { required: true })}>
                                    {Object.keys(channels).map(i => (
                                        <option key={'channel' + i} value={channels[i]}>{channels[i]}</option>
                                    ))}
                                </FormSelect>
                                {errors.company_channel && <span className="text-danger">Байгууллагын суваг сонгох шаардлагатай</span>}
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row className="mb-3">
                    <Col lg="2" md="4"><Form.Label> Захиалгын тайлбар:</Form.Label></Col>
                    <Col lg="10" md="8">
                        <CKEditor
                            data={getValues('description')}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                                setValue('description', editor.getData())
                            }} />
                    </Col>
                </Row>

                <div className="card bg-light mb-3">
                    <div className="card-body">
                        <h6 className="mb-3">Худалдааны төлөөлөгч сонгох</h6>
                        <Row className="mb-3">
                            <Col lg="6">
                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">{khtList['1']}:</Form.Label>
                                    <Col>
                                        <Form.Select lg="9" {...register('kht.apu_kht')}>
                                            <option value="null">Хоосон</option>
                                            {_.map(kht['1'], (item, i) => {
                                                return <option value={item['id']} key={'apu_kht' + i}>{item['name']}</option>
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">{khtList['2']}:</Form.Label>
                                    <Col>
                                        <Form.Select lg="9" {...register('kht.apu_sbb')}>
                                            <option value="null">Хоосон</option>
                                            {_.map(kht['2'], (item, i) => {
                                                return <option value={item['id']} key={'apu_sbb' + i}>{item['name']}</option>
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">{khtList['3']}:</Form.Label>
                                    <Col>
                                        <Form.Select lg="9" {...register('kht.apu_wine')}>
                                            <option value="null">Хоосон</option>
                                            {_.map(kht['3'], (item, i) => {
                                                return <option value={item['id']} key={'apu_wine' + i}>{item['name']}</option>
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group as={Row}>
                                    <Form.Label column lg="3">{khtList['4']}:</Form.Label>
                                    <Col>
                                        <Form.Select lg="9" {...register('kht.apu_draught')}>
                                            <option value="null">Хоосон</option>
                                            {_.map(kht['4'], (item, i) => {
                                                return <option value={item['id']} key={'apu_draught' + i}>{item['name']}</option>
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="card bg-light mb-3">
                    <div className="card-body">
                        <h6 className="mb-3">Мэнюны төрөл</h6>
                        <Row>
                            {_.map(menus, (menu, k) => {
                                return (
                                    <Col lg={menu.col !== undefined ? menu.col : 6} className="mb-3" key={'menu-' + k}>
                                        <Form.Group as={Row}>
                                            <Form.Label column lg="12">{menu['name']}:</Form.Label>
                                            <Col lg="12">
                                                {menu.items !== undefined ? (
                                                    <Form.Select {...register(`menu.${menu['key']}`)}>
                                                        <option value="null">Хоосон</option>
                                                        {_.map(menu['items'], (item, i) => {
                                                            return (
                                                                <option value={item} key={menu['key'] + i}>{item}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control defaultValue="0"  {...register(`menu.${menu['key']}`)} />
                                                )}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body bg-light">
                        <h4>Архи</h4>
                        <div className="table-responsive">
                            <table className="table table-borderles table-striped align-middle">
                                <thead>
                                    <tr key="vodka-head">
                                        <th width="250">Архи Нэр төрөл</th>
                                        <th>Шилтэй үнэ</th>
                                        <th>Грамм үнэ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(vodkaList, (item, i) => {
                                        return (
                                            <tr key={'vodka' + i}>
                                                <td>{item}</td>
                                                <td>
                                                    <Form.Control placeholder={item + ' - Шилтэй үнэ'} {...register(`price.vodka.${i}.bottle`)} />
                                                </td>
                                                <td>
                                                    <Form.Control placeholder={item + ' - Грамм үнэ'} {...register(`price.vodka.${i}.gramm`)} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body bg-light">
                        <h4>Ус, ундаа нэр төрөл</h4>
                        <div className="table-responsive">
                            <table className="table table-borderles table-striped align-middle">
                                <thead>
                                    <tr key="water-head">
                                        <th width="250">Ус ундаа</th>
                                        <th>Үнэ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(waterList, (item, i) => {
                                        return (
                                            <tr key={'beer-' + i}>
                                                <td>{item}</td>
                                                <td>
                                                    <Form.Control {...register(`price.water.${i}`)} placeholder={item + ' - үнэ'} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div className="card mb-3">
                    <div className="card-body bg-light">
                        <h4>Пиво нэр</h4>
                        <div className="table-responsive">
                            <table className="table table-borderles table-striped align-middle">
                                <thead>
                                    <tr key="beer-head">
                                        <th width="250">Пиво</th>
                                        <th>Үнэ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(beerList, (item, i) => {
                                        return (
                                            <tr key={'beer-' + i}>
                                                <td>{item}</td>
                                                <td>
                                                    <Form.Control {...register(`price.beer.${i}`)} placeholder={item + ' - үнэ'} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body bg-light">
                        <h4>Brown Нэр төрөл</h4>
                        <div className="table-responsive">
                            <table className="table table-borderles table-striped align-middle">
                                <thead>
                                    <tr key="wiskey-head">
                                        <th width="250">Brown нэр төрөл</th>
                                        <th>Шилтэй үнэ</th>
                                        <th>Грамм үнэ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(brownList, (item, i) => {
                                        return (
                                            <tr key={'beer-' + i}>
                                                <td>{item}</td>
                                                <td>
                                                    <Form.Control {...register(`price.wiskey.${i}.bottle`)} placeholder={item + ' - Шилтэй үнэ'} />
                                                </td>
                                                <td>
                                                    <Form.Control {...register(`price.wiskey.${i}.gramm`)} placeholder={item + ' - Грамм үнэ'} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div className="card mb-3">
                    <div className="card-body bg-light">
                        <h4>Дарс нэр</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered align-middle">
                                <thead>
                                    <tr key="wine-head">
                                        <th width="250">Дарс нэр төрөл</th>
                                        <th width="10">Төрөл</th>
                                        <th>Шилтэй үнэ</th>
                                        <th>Грамм үнэ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(wineList, (item, i) => {
                                        return _.map(item, (sub, s) => {
                                            return (
                                                <tr key={'wine-' + i + '-' + s}>
                                                    <td>{sub}</td>
                                                    <td>{i}</td>
                                                    <td><FormControl {...register(`price.wine.${i}.${s}.bottle`)} placeholder={sub + ' - Шилтэй үнэ'} /></td>
                                                    <td><FormControl {...register(`price.wine.${i}.${s}.gramm`)} placeholder={sub + ' - Грамм үнэ'} /></td>
                                                </tr>
                                            )
                                        })
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



                <div className="position-fixed start-0 bottom-0 w-100 py-4 bg-dark">
                    <div className="container">
                        <button type="submit" className="btn btn-primary w-100">Хадгалах</button>
                    </div>
                </div>

                <div style={{ height: '100px' }}></div>

            </form>
        </Container>
    );
}

export default Order;