import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import axios from 'axios';
import { apiUrl, port } from '../services/config';
import { Link } from "react-router-dom";

function Header(props) {

    const logout = async () => {

        window.localStorage.removeItem('session_id')

        let data = JSON.stringify({
            "jsonrpc": "2.0",
            "params": {
            // "session_id": localStorage.getItem('session_id')
            }
        });

        let config = {
            method: 'POST',
            url: apiUrl +':'+ port +'/web/session/destroy',
            headers: {
                'Content-type': 'application/json',
                // 'Cookie': 'session_id='+localStorage.getItem('session_id')
            },
            withCredentials: true,
            data: data
        };

        await axios(config);

        window.location.reload();

    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/" className="nav-link">Самбар</Link>
                        <Link to="/new" className="nav-link">Шинэ захиалга</Link>
                        <Link to="/report" className="nav-link">Репорт</Link>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={()=>logout()}>Гарах</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;