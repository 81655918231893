import Odoo from './Odoo';
import { apiUrl, dbName, port } from './config';

export const Call = (context = null) => {
    return new Odoo({
        host: apiUrl,
        port: port,
        database: dbName,
        context: context
    })
}
