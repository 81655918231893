export const menu = [
    {
        'key': 'turul',
        'name': 'Меню төрөл',
        'items': [
            'Цаасан',
            'Цаасан бүрэлттэй (Матт)',
            'Цаасан бүрэлттэй (Гялгар)',
            'Цаасан бүрэлттэй (Ламинатор)',
            'Пластик (Дугуй)',
            'Пластик (Дөрвөлжин)',
            'Хавтастай ',
            'Хавтастай (Боолт)',
            'Хавтастай (Хар Нуруу)',
            'Хавтастай (Төмөр хавчаар)',
            'PVC',
            'Хулдаасан фото мен',
            'Офсет мэню'
        ]
    },
    {
        'key': 'size',
        'name': 'Меню хэмжээ',
        'items': [
            'A3',
            'A4',
            'A5',
        ]
    },
    {
        'key': 'qr_menu',
        'name': 'QR Menu',
        'items': [
            'QR /Зурган мэню/',
            'QR-Plus /Нэмэлт мэдээлэл/',
            'QR-Text /Нэмэлт мэдээлэл/',
            'QR-Multi /Олон менютэй/',
        ]
    },
    {
        'key': 'table_qr',
        'name': 'Ширээний QR',
        'items': [
            'Стикер',
            'Пластик',
            'Гурвалжин цаасан',
            'Гурвалжин цаасан PVC',
            'L хэлбэрийн акрилан стикер',
            'L хэлбэрийн акрилан UV',
        ]
    },
    {
        'key': 'menu_page_count',
        'name': 'Хуудасны тоо',
        'col': 4
    },
    {
        'key': 'menu_print_count',
        'name': 'Хэвлэх хувь',
        'col': 4
    },
    {
        'key': 'qr_menu_print_count',
        'name': 'Ширээний (QR) Хэвлэх хувь',
        'col': 4
    }
]