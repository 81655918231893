import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Call } from '../services/Call';

import { menu } from './json/menu'
import beerList from './json/beer.json';
import brownList from './json/brown.json';
import vodkaList from './json/vodka.json';
import waterList from './json/water.json';
import wineList from './json/wine.json';


function Print(props) {

    const [taskData, setTaskData] = useState([]);
    let params = useParams();

    useEffect(() => {
        let task = {
            domain: [['id', '=', params.id]],
            fields: [
                'id',
                'name',
                'description',
                'x_menu_phone',
                'x_menu_type',
                'x_menu_price',
                'x_menu_data',
                'x_menu_channel',
                'x_menu_code',
                'x_menu_partners'
            ],
            limit: 1
        }

        const taskContext = (err, res) => {
            let data = res[0];
            data['x_menu_price'] = JSON.parse(data['x_menu_price'])
            data['x_menu_partners'] = JSON.parse(data['x_menu_partners'])
            data['x_menu_data'] = JSON.parse(data['x_menu_data'])
            setTaskData(data)
        }

        Call().search_read('project.task', task, taskContext)

    }, [params, setTaskData]);

    if (Object.keys(taskData).length > 0) {
        return (
            <Container>
                <div className="py-3">
                    <h6 className="text-center mb-3 fw-bolder">МЕНЮ ЗАХИАЛГЫН ХУУДАС Дугаар: ХБГ/АШМ - {taskData['id']}</h6>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td colspan="2"><strong>Харилцагчийн мэдээлэл:</strong></td>
                            </tr>
                            <tr>
                                <td width="50%"><strong>Харилцагчийн нэр:</strong> {taskData['name']}</td>
                                <td width="50%"><strong>Харилцагчийн код:</strong> {taskData['x_menu_code']}</td>
                                <td width="50%"><strong>Харилцагчийн утас:</strong> {taskData['x_menu_phone']}</td>
                            </tr>
                            <tr>
                                <td><strong>Харилцагчийн ангилал:</strong> {taskData['x_menu_type']}</td>
                                <td><strong>Харилцагчийн утас:</strong> {taskData['x_menu_phone']}</td>
                            </tr>
                            <tr>
                                <td colspan="2"><strong>Харилцагчийн суваг:</strong> {taskData['x_menu_channel']}</td>
                            </tr>
                            <tr>
                                <td><strong>Хуудасны тоо:</strong></td>
                                <td><strong>Меню хэвлэх хувь:</strong></td>
                            </tr>
                            <tr>
                                <td><h6 className="fw-bolder">AПУ Трейдинг:</h6> ХТ Код: <br/>ХТ Нэр: <br/>ХТ Утас:</td>
                                <td><h6 className="fw-bolder">СББ Трейд:</h6> ХТ Код: <br/>ХТ Нэр: <br/>ХТ Утас:</td>
                            </tr>
                            <tr>
                                <td><h6 className="fw-bolder">Import/wine/:</h6> ХТ Код: <br/>ХТ Нэр: <br/>ХТ Утас:</td>
                                <td><h6 className="fw-bolder">Draught beer:</h6> ХТ Код: <br/>ХТ Нэр: <br/>ХТ Утас:</td>
                            </tr>
                            <tr>
                                <td colSpan="2"><strong>Меню захиалгын мэдээлэл:</strong></td>
                            </tr>
                            <tr>
                                <td><strong>Менюний төрөл:</strong> {taskData['x_menu_data']['turul']}</td>
                                <td>
                                    <strong>Менюний хэмжээ:</strong> {taskData['x_menu_data']['size']} &nbsp;
                                    <strong>Менюний хэвлэх:</strong> {taskData['x_menu_data']['menu_print_count']}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2"><strong>Дизайны санал:</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <strong>QR Меню захиалгын мэдээлэл:</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div><strong>Менюний төрөл:</strong> {taskData['x_menu_data']['qr_menu']}</div>
                                    <div><strong>Ширээний QR Менюний төрөл:</strong> {taskData['x_menu_data']['table_qr']}</div>
                                    <div><strong>Меню хэвлэх хувь:</strong> {taskData['x_menu_data']['qr_menu_print_count']}</div>
                                </td>
                                <td><strong>Нэмэлт санал:</strong></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Архи Нэр төрөл</th>
                                <th>Шилтэй үнэ</th>
                                <th>Грамм үнэ</th>
                            </tr>
                        </thead>
                        <tbody>
                        {_.map(vodkaList, (item, i) => {
                            return (
                                <tr>
                                    <td>{item}</td>
                                    <td>
                                        { (taskData['x_menu_price']['vodka'][i] !== undefined && taskData['x_menu_price']['vodka'][i]['bottle'] !== undefined ) ? (
                                            taskData['x_menu_price']['vodka'][i]['bottle']
                                        ): '' }
                                    </td>
                                    <td> 
                                        { (taskData['x_menu_price']['vodka'][i] !== undefined && taskData['x_menu_price']['vodka'][i]['gramm'] !== undefined ) ? (
                                            taskData['x_menu_price']['vodka'][i]['gramm']
                                        ): '' }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Ус, ундаа нэр төрөл</th>
                                <th>Үнэ</th>
                            </tr>
                        </thead>
                        <tbody>
                        {_.map(waterList, (item, i) => {
                            return (
                                <tr>
                                    <td>{item}</td>
                                    <td>
                                        { (taskData['x_menu_price']['water'][i] !== undefined ) ? (
                                            taskData['x_menu_price']['water'][i]
                                        ): '' }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Пиво Нэр төрөл</th>
                                <th>Үнэ</th>
                            </tr>
                        </thead>
                        <tbody>
                        {_.map(beerList, (item, i) => {
                            return (
                                <tr>
                                    <td>{item}</td>
                                    <td>
                                        { (taskData['x_menu_price']['beer'][i] !== undefined ) ? (
                                            taskData['x_menu_price']['beer'][i]
                                        ): '' }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Brown Нэр төрөл</th>
                                <th>Шилтэй үнэ</th>
                                <th>Грамм үнэ</th>
                            </tr>
                        </thead>
                        <tbody>
                        {_.map(brownList, (item, i) => {
                            return (
                                <tr>
                                    <td>{item}</td>
                                    <td>
                                        { (taskData['x_menu_price']['wiskey'][i] !== undefined && taskData['x_menu_price']['wiskey'][i]['bottle'] !== undefined ) ? (
                                            taskData['x_menu_price']['wiskey'][i]['bottle']
                                        ): '' }
                                    </td>
                                    <td> 
                                        { (taskData['x_menu_price']['wiskey'][i] !== undefined && taskData['x_menu_price']['wiskey'][i]['gramm'] !== undefined ) ? (
                                            taskData['x_menu_price']['wiskey'][i]['gramm']
                                        ): '' }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Дарс Нэр</th>
                                <th>Төрөл</th>
                                <th>Шилтэй үнэ</th>
                                <th>Грамм үнэ</th>
                            </tr>
                        </thead>
                        <tbody>
                        {_.map(wineList, (item, i) => {
                            return _.map(item, (sub, s) => {
                                return (
                                    <tr>
                                        <td>{sub}</td>
                                        <td>{i}</td>
                                        <td> 
                                            { (taskData['x_menu_price']['wine'][i] !== undefined && taskData['x_menu_price']['wine'][i][s] !== undefined && taskData['x_menu_price']['wine'][i][s]['bottle'] !== undefined ) ? (
                                                    taskData['x_menu_price']['wine'][i][s]['bottle']
                                            ): '' }
                                        </td>
                                        <td> 
                                            { (taskData['x_menu_price']['wine'][i] !== undefined && taskData['x_menu_price']['wine'][i][s] !== undefined && taskData['x_menu_price']['wine'][i][s]['gramm'] !== undefined ) ? (
                                                    taskData['x_menu_price']['wine'][i][s]['gramm']
                                            ): '' }
                                        </td>
                                    </tr>
                                )
                            })
                        })}
                        </tbody>
                    </table>
                    

                </div>
            </Container>
        )
    }
    else {
        return (
            <Container>
                <p>Уншиж байна</p>
            </Container>
        )
    }
}

export default Print;